import React from "react";
import Button from "components/Button";
import NodeNewsPreviewList from "components/News/NodeNewsPreviewList";
import Pattern5 from "components/Patterns/Pattern5";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";

export default function NewsHomeSection({ nodes }) {
  const { t } = useTranslation("home");

  return (
    <section className="relative pt-24 pb-28 lg:pt-28 lg:pb-32 bg-primary-light overflow-hidden">
      <div
        className="hidden lg:block absolute top-0 right-0 -translate-y-1/4"
        aria-hidden="true"
      >
        <Pattern5 className="text-primary" />
      </div>

      <div className="relative container mx-auto px-4">
        <div
          className="hidden lg:block absolute top-0 left-0 text-default text-[210px] font-important font-bold leading-none opacity-5"
          aria-hidden="true"
        >
          {t("news.decoration")}
        </div>

        <div className="text-important uppercase text-primary text-sm font-important font-medium tracking-wider mb-5 before:content-['///'] before:mr-2">
          {t("news.pre_title")}
        </div>

        <h2 className="mb-16 text-3xl lg:text-5xl font-important font-bold">
          {t("news.title")}
        </h2>

        <NodeNewsPreviewList nodes={nodes} />

        <div className="text-center mt-16">
          <Link href={t("common:news.path")} passHref>
            <Button as="a">{t("news.more")}</Button>
          </Link>
        </div>
      </div>
    </section>
  );
}
