import { ArrowRightIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import React from "react";

export default function MoreLink({ link, variant }) {
  const { t } = useTranslation("common");

  const variants = {
    primary: {
      background: "group-hover:bg-primary",
      icon: "text-primary",
    },
    secondary: {
      background: "group-hover:bg-secondary",
      icon: "text-secondary",
    },
    tertiary: {
      background: "group-hover:bg-tertiary",
      icon: "text-tertiary",
    },
  };

  return (
    <div className="absolute bottom-0 right-0">
      <Link href={link} passHref>
        <a
          className={classNames(
            "block p-5 border-t border-l bg-white transition-colors",
            variants[variant].background
          )}
        >
          <ArrowRightIcon
            className={classNames(
              "h-4 w-4 text-primary group-hover:text-white transition-colors",
              variants[variant].icon
            )}
          />
          <span className="sr-only">{t("utils.more_link")}</span>
        </a>
      </Link>
    </div>
  );
}
