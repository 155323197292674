import React from "react";

export default function Pattern5({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="242"
      height="601"
      viewBox="0 0 242 601"
      className={className}
    >
      <defs>
        <rect id="pattern5rect" width="242" height="601" x="0" y="0" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="pattern5mask" fill="#fff">
          <use xlinkHref="#pattern5rect" />
        </mask>
        <path
          fill="currentColor"
          d="M901.95157,-92 L910,-83.9515699 L222.321891,599 L219,595.678109 L901.95157,-92 Z M871.219995,-92 L879,-84.2177785 L192.134167,598 L189,594.863616 L871.219995,-92 Z M841.401697,-92 L849,-84.401721 L161.915602,598 L159,595.084407 L841.401697,-92 Z M810.61885,-92 L818,-84.6210681 L130.694741,598 L128,595.305259 L810.61885,-92 Z M780.801015,-92 L788,-84.8010147 L100.509107,598 L98,595.490893 L780.801015,-92 Z M751.023018,-92 L758,-85.0207988 L70.3256607,598 L68,595.676558 L751.023018,-92 Z M720.250335,-91 L727,-84.2503571 L40.1013734,598 L38,595.896417 L720.250335,-91 Z M690.434611,-91 L697,-84.4346109 L9.88247731,598 L8,596.117523 L690.434611,-91 Z M659.616132,-91 L666,-84.6205665 L1.8448692,575 L0,573.155131 L659.616132,-91 Z M629.834225,-91 L636,-84.8342467 L1.84596064,545 L0,543.154046 L629.834225,-91 Z M599.059848,-91 L605,-85.0620649 L1.84410674,514 L0,512.155893 L599.059848,-91 Z M569.244788,-91 L575,-85.2448103 L1.84521628,484 L0,482.154791 L569.244788,-91 Z M539.460471,-91 L545,-85.4582967 L1.8465097,454 L0,452.153505 L539.460471,-91 Z M508.686382,-91 L514,-85.6908155 L1.84435972,423 L0,421.15564 L508.686382,-91 Z M478.908626,-91 L484,-85.9086259 L1.84576177,393 L0,391.154238 L478.908626,-91 Z M449.086527,-91 L454,-86.0865272 L1.84727038,363 L0,361.15273 L449.086527,-91 Z M418.279775,-90 L423,-85.279775 L1.84463465,333 L0,331.155365 L418.279775,-90 Z M388.495003,-91 L393,-86.4928088 L1.84638319,302 L0,300.153627 L388.495003,-91 Z M357.726215,-90 L362,-85.726215 L1.84333286,272 L0,270.156667 L357.726215,-90 Z M327.905999,-90 L332,-85.9038083 L1.8451837,242 L0,240.154829 L327.905999,-90 Z M298.120718,-90 L302,-86.1207179 L1.84748866,212 L0,210.152511 L298.120718,-90 Z M267.350594,-90 L271,-86.3505936 L1.84353741,181 L0,179.156463 L267.350594,-90 Z M237.529692,-90 L241,-86.5319112 L1.84609719,151 L0,149.151684 L237.529692,-90 Z"
          mask="url(#pattern5mask)"
        />
      </g>
    </svg>
  );
}
