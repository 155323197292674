import React from "react";
import NodeNewsPreviewTeaser, {
  NodeNewsPreviewLargeTeaser,
} from "components/News/NodeNewsPreviewTeaser";

export default function NodeNewsPreviewList({ nodes }) {
  const [first, second, third] = nodes;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      {first && <NodeNewsPreviewLargeTeaser node={first} />}

      {second && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-8">
          <NodeNewsPreviewTeaser node={second} />

          {third && <NodeNewsPreviewTeaser node={third} />}
        </div>
      )}
    </div>
  );
}
