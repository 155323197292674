import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/outline";
import Body from "components/Body";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import MoreLink from "components/MoreLink";

export const COLORS_MAPPING = {
  primary: "text-primary",
  secondary: "text-secondary",
  tertiary: "text-tertiary",
  red: "text-red-500",
  orange: "text-orange-600",
  yellow: "text-yellow-500",
  green: "text-green-600",
  cyan: "text-cyan-600",
  sky: "text-sky-600",
  purple: "text-purple-600",
  fuchsia: "text-fuchsia-600",
  pink: "text-pink-500",
};

export default function NodeNewsPreviewTeaser({ node }) {
  const router = useRouter();
  const { t } = useTranslation("common");

  return (
    <article
      className="group cursor-pointer relative flex flex-col"
      onClick={() => router.push(node.path.alias)}
    >
      <div className="flex flex-col order-2 relative p-8 pb-16 lg:p-16 md:ml-8 lg:ml-52 md:-mt-20 lg:mt-20 bg-white shadow-spread z-10">
        <h2 className="order-2 text-xl font-important font-bold mb-8">
          <Link href={node.path.alias} passHref>
            <a>{node.title}</a>
          </Link>
        </h2>

        {node.body && (
          <div className="order-3 prose max-w-none line-clamp-2 mb-4 text-sm">
            <Body
              value={
                node.body.summary
                  ? node.body.summary
                  : node.body.value.replace(/(<([^>]+)>)/gi, "")
              }
            />
          </div>
        )}

        <div
          className={classNames(
            "order-1 mb-4 uppercase font-important font-medium tracking-wider text-sm after:content-['/'] after:ml-2",
            node.field_type.field_color
              ? COLORS_MAPPING[node.field_type.field_color]
              : "text-primary"
          )}
        >
          {node.field_type.name}
        </div>

        <MoreLink link={node.path.alias} variant="primary" />
      </div>

      <div className="lg:absolute top-0 left-0 w-full md:w-5/6 lg:w-1/2">
        <div className="aspect-w-16 aspect-h-9 md:aspect-h-11 overflow-hidden">
          {node.field_image?.image_style_uri.medium_16by10 && (
            <Image
              src={node.field_image.image_style_uri.medium_16by10}
              {...node.field_image.resourceIdObjMeta}
              layout="fill"
              objectFit="cover"
              className="group-hover:scale-110 transition"
            />
          )}
        </div>
      </div>
    </article>
  );
}

export function NodeNewsPreviewLargeTeaser({ node }) {
  const router = useRouter();
  const { t } = useTranslation("common");

  return (
    <article
      className="group cursor-pointer relative flex flex-col"
      onClick={() => router.push(node.path.alias)}
    >
      <div className="flex flex-col order-2 relative p-8 pb-16 lg:p-16 md:ml-32 lg:ml-20 lg:mr-5 md:-mt-32 lg:-mt-16 bg-white shadow-spread z-10">
        <h2 className="order-2 text-xl lg:text-2xl font-important font-bold mb-8">
          <Link href={node.path.alias} passHref>
            <a>{node.title}</a>
          </Link>
        </h2>

        {node.body && (
          <div className="order-3 prose max-w-none line-clamp-3 mb-4">
            <Body
              value={
                node.body.summary
                  ? node.body.summary
                  : node.body.value.replace(/(<([^>]+)>)/gi, "")
              }
            />
          </div>
        )}

        <div
          className={classNames(
            "order-1 mb-4 uppercase font-important font-medium tracking-wider text-sm after:content-['/'] after:ml-2",
            node.field_type.field_color
              ? COLORS_MAPPING[node.field_type.field_color]
              : "text-primary"
          )}
        >
          {node.field_type.name}
        </div>

        <div className="absolute bottom-0 right-0">
          <Link href={node.path.alias} passHref>
            <a className="block p-5 border-t border-l bg-white group-hover:bg-primary transition-colors">
              <ArrowRightIcon className="h-5 w-5 text-primary group-hover:text-white transition-colors" />
              <span className="sr-only">{t("utils.more_link")}</span>
            </a>
          </Link>
        </div>
      </div>
      <div className="order-1 w-full md:w-1/2 lg:w-full">
        <div className="aspect-w-16 aspect-h-9 lg:aspect-h-7 h-full overflow-hidden">
          {node.field_image?.image_style_uri.medium_16by7 && (
            <Image
              src={node.field_image.image_style_uri.medium_16by7}
              {...node.field_image.resourceIdObjMeta}
              layout="fill"
              objectFit="cover"
              className="group-hover:scale-110 transition"
            />
          )}
        </div>
      </div>
    </article>
  );
}
